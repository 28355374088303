import { useMemo } from 'react'
import { OrganizationModel } from '../models/OrganizationModel'
import { useCurrentUser } from './useCurrentUser'
import { useOrganizationData } from './useOrganizationData'

export const useCurrentOrganization = () => {
  const { orgId, user } = useCurrentUser()
  const { data: organization, isFetching } = useOrganizationData({ orgId, enabled: !user.organization && !!orgId })

  const organizationModel = useMemo(() => {
    if (user.organization) {
      return new OrganizationModel(user.organization)
    }

    return organization ? new OrganizationModel(organization) : null
  }, [organization, user])

  return {
    isFetching: user.organization ? false : isFetching,
    organization: user.organization || organization,
    organizationModel,
  }
}
