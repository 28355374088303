import { isEmpty } from 'lodash'
import { Organization } from '@/server/services/Auth0/Auth0.types'
import { PageHref } from '../constants/pages'

export class OrganizationModel {
  id: Organization['id']
  displayName: Organization['display_name']
  pages: PageHref[]
  metadata: Organization['metadata']

  constructor(organization: Organization) {
    this.id = organization.id
    this.displayName = organization.display_name
    this.pages = organization.metadata?.pages || []
    this.metadata = organization.metadata
  }

  hasPageRestrictions = () => !isEmpty(this.pages)
  isPageAvailable = (page: PageHref) => !this.hasPageRestrictions() || this.pages.includes(page)
  isCopilotAvailable = () => this.metadata?.copilot !== false
}
