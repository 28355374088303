import AiIcon from './assets/ai-icon.svg'
import ArrowDownIcon from './assets/arrow-down-icon.svg'
import ArrowRightIcon from './assets/arrow-right-icon.svg'
import BellIcon from './assets/bell-icon.svg'
import BombIcon from './assets/bomb-icon.svg'
import CalendarIcon from './assets/calendar-icon.svg'
import CheckIcon from './assets/check-icon.svg'
import CloseIcon from './assets/close-icon.svg'
import ContactIcon from './assets/contact-icon.svg'
import CoordinatesIcon from './assets/coordinates-icon.svg'
import CopyIcon from './assets/copy-icon.svg'
import CrimeIcon from './assets/crime-icon.svg'
import DashboardIcon from './assets/dashboard-icon.svg'
import DataGraphIcon from './assets/data-graph-icon.svg'
import DocumentMapIcon from './assets/document-map-icon.svg'
import DownloadIcon from './assets/download-icon.svg'
import EngineWarningIcon from './assets/engine-warning.svg'
import ExplosionIcon from './assets/explosion-icon.svg'
import FileHtmlIcon from './assets/file-html-icon.svg'
import FilePdfIcon from './assets/file-pdf-icon.svg'
import FileWordIcon from './assets/file-word-icon.svg'
import FilterIcon from './assets/filter-icon.svg'
import FolderIcon from './assets/folder-icon.svg'
import GridIcon from './assets/grid-icon.svg'
import InfoIcon from './assets/info-icon.svg'
import LaunchIcon from './assets/launch-icon.svg'
import ListIcon from './assets/list-icon.svg'
import LocationIcon from './assets/location-icon.svg'
import LogoFullIcon from './assets/logo-full-icon.svg'
import LogoIcon from './assets/logo-icon.svg'
import LogOutIcon from './assets/logout-icon.svg'
import MapIcon from './assets/map-icon.svg'
import MegaphoneIcon from './assets/megaphone-icon.svg'
import MenuIcon from './assets/menu-icon.svg'
import NewspaperIcon from './assets/newspaper-icon.svg'
import ReportIcon from './assets/reports-icon.svg'
import SendIcon from './assets/send-icon.svg'
import SortIcon from './assets/sort-icon.svg'

export {
  AiIcon,
  GridIcon,
  ListIcon,
  EngineWarningIcon,
  MegaphoneIcon,
  CrimeIcon,
  CheckIcon,
  ExplosionIcon,
  BombIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  LocationIcon,
  LogoIcon,
  LogoFullIcon,
  LogOutIcon,
  BellIcon,
  MenuIcon,
  MapIcon,
  ReportIcon,
  InfoIcon,
  DashboardIcon,
  DownloadIcon,
  FilterIcon,
  NewspaperIcon,
  DocumentMapIcon,
  CalendarIcon,
  CoordinatesIcon,
  ContactIcon,
  CloseIcon,
  LaunchIcon,
  FolderIcon,
  FilePdfIcon,
  FileWordIcon,
  FileHtmlIcon,
  DataGraphIcon,
  SortIcon,
  SendIcon,
  CopyIcon,
}
