import { CurrentUser } from '@/server/services/Auth0/Auth0.types'

export class CurrentUserModel {
  private user: Partial<CurrentUser>
  name: CurrentUser['name']
  email: CurrentUser['email']
  org_id: CurrentUser['org_id']
  session_id: CurrentUser['session_id']
  organization: CurrentUser['organization']

  constructor(user: Partial<CurrentUser> = {}) {
    this.user = user
    this.name = user?.nickname || user?.name || ''
    this.email = user?.email || ''
    this.org_id = user.org_id!
    this.session_id = user.sid as string
    this.organization = user.organization
  }
}
