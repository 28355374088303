import colors from './variables.module.scss'

type Colors = {
  borderColor: string
  menuColor: string

  // Basic colors
  White: string
  Black: string
  Red: string
  Neutral: string
  Neutral_20: string
  Neutral_80: string
  Neutral_90: string
  Blackcurrant_50: string
  Blue: string
  Sienna: string
}

export const Colors = colors as Colors
