import { AUTH0_PATH } from '@/components/layers/Auth0Layer/constants'
import { OrganizationModel } from '../models/OrganizationModel'

export { AUTH0_PATH }

export type PageHref = ValueOf<typeof PAGE>

export const PAGE = {
  INDEX: '/',
  REPORTS: '/reports',
  /** @deprecated */
  LOGIN: '/login', // will be added
  WAITING: '/waiting' as '/waiting',
}

export const PAGE_TITLE_MAP: Partial<Record<PageHref, string>> = {
  [PAGE.INDEX]: 'Situation Awareness',
  [PAGE.REPORTS]: 'Reports',
}

export const REDIRECT_URL_PARAM = 'redirectUrl' as 'redirectUrl'

// Pages do not need authorization
export const PUBLIC_PAGES: PageHref[] = [PAGE.LOGIN]

export const getPageTitle = (page: PageHref) => PAGE_TITLE_MAP[page]

const isPageVisible = (organization: OrganizationModel, pageUrl: PageHref) => {
  if (!organization.hasPageRestrictions()) {
    return true
  }

  return organization.isPageAvailable(pageUrl)
}

export const getPagesByUserPermissions = ({
  organization,
}: {
  organization: OrganizationModel
}): Omit<Record<PageHref, boolean>, '/login'> => ({
  [PAGE.INDEX]: isPageVisible(organization, PAGE.INDEX),
  [PAGE.REPORTS]: isPageVisible(organization, PAGE.REPORTS),
})
