'use client'

import { useCallback, useMemo } from 'react'
import { UserProfile, useUser } from '@auth0/nextjs-auth0/client'
import { useRouter } from 'next/navigation'
import { isEmpty } from 'lodash'
import { CurrentUser } from '@/server/services/Auth0/Auth0.types'
import { Api } from '../api'
import { AUTH0_PATH, REDIRECT_URL_PARAM } from '../constants/pages'

type Status = 'loading' | 'authenticated' | 'unauthenticated'

export const useNextAuth = () => {
  const router = useRouter()
  const { user, isLoading } = useUser()

  const status = useMemo<Status>(() => {
    if (isLoading) return 'loading'
    return user ? 'authenticated' : 'unauthenticated'
  }, [isLoading, user])

  const logout = useCallback(async () => {
    router.push(AUTH0_PATH.LOGOUT)
  }, [router])

  const loginToOrganization = useCallback(
    ({ org_id }: Pick<UserProfile, 'org_id'>) => {
      if (org_id !== user?.org_id) {
        const url = Api.user.getLoginPathToOrganization({
          org_id: org_id!,
          [REDIRECT_URL_PARAM]: window.location.pathname,
        })
        router.push(url)
      }
    },
    [user],
  )

  return {
    isLoggedIn: !isEmpty(user),
    session: user ? ({ user } as { user: CurrentUser }) : undefined,
    status,
    logout,
    loginToOrganization,
  }
}
