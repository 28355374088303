import { CurrentUserData } from '@/app/api/user/current/types'
import { UserOrganizationData } from '@/app/api/user/organization/types'
import { UserOrganizationsData } from '@/app/api/user/organizations/types'
import { REDIRECT_URL_PARAM } from '../constants/pages'
import { ROUTE, instance } from './instance'

export const user = {
  current: async () => {
    const { data } = await instance.get<CurrentUserData>(ROUTE.USER.CURRENT)
    return data
  },
  organizations: async () => {
    const { data } = await instance.get<UserOrganizationsData>(ROUTE.USER.ORGANIZATIONS)
    return data
  },
  organization: async (orgId: string) => {
    const { data } = await instance.get<UserOrganizationData>(ROUTE.USER.ORGANIZATION, { params: { orgId } })
    return data
  },
  getLoginPathToOrganization: ({ org_id, redirectUrl }: { org_id: string; [REDIRECT_URL_PARAM]?: string }) => {
    const urlParams = new URLSearchParams({ org_id })
    if (redirectUrl) {
      urlParams.append('redirectUrl', redirectUrl)
    }
    return `${ROUTE.USER.SILENT_LOGIN}?${urlParams.toString()}`
  },
}
