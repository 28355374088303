import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'

export const QUERY_KEY_ORGANIZATION = 'organizations-data'

export const useOrganizationData = ({ orgId, enabled }: { orgId: string; enabled?: boolean }) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_ORGANIZATION, orgId],
    queryFn: () => Api.user.organization(orgId),
    enabled: typeof enabled === 'undefined' ? !!orgId : enabled,
  })

  return { data, ...query }
}
