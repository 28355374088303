import { ReportFileItem, ReportThumbnailItem } from '@/server/services/GoogleCloudBuckets/GoogleCloudBuckets.types'
import { ROUTE, instance } from './instance'

export const reports = {
  getReportsList: async () => {
    const { data } = await instance.get<ReportFileItem[]>(ROUTE.REPORTS.LIST)
    return data
  },

  getSignedUrl: async (payload: Pick<ReportFileItem, 'name'> & { asAttachment?: boolean }) => {
    const { data } = await instance.post<{ signedUrl: string }>(ROUTE.REPORTS.DOWNLOAD_URL, payload)
    return data
  },

  getReportThumbnail: async (payload: { thumbnailName: ReportThumbnailItem['name'] }) => {
    const { data } = await instance.get<{ imageSrc: string }>(ROUTE.REPORTS.THUMBNAIL, {
      params: { thumbnailName: payload.thumbnailName },
    })
    return data
  },
}
